import React, { useEffect, useState } from 'react';
import { Popover, PopoverBody, PopoverHeader, Input } from 'reactstrap';
import { ModalBtnClose, RedButton } from '../CommonComponent';
import SpinnerLoading from '../SpinnerLoading';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

//datagrid
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

//SCSS
import './potential-customer.scss';

//Constant
const helper = require('../../utils/helper');
const enums = require('../../enums');

export default function PotentialCustomer({
  customerID,
  customerEmail,
  customerPhone,
  updateProfileData,
  origin,
  disabledProps,
  setDisableFunc,
  isReply = null,
  isForward = null,
  ...props
}) {
  //input
  const fieldSetup = {
    mobile: {
      label: 'Mobile',
      type: 'text',
      value: '',
      placeholder: 'Enter Mobile No',
    },

    email: {
      label: 'Email',
      type: 'text',
      value: '',
      placeholder: 'Email address',
    },
  };

  const [guestData, setGuestData] = useState({});
  const [toggleLookup, setToggleLookup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleLookupNew, setToggleLookupNew] = useState(false);
  const [value, setValue] = useState(0);
  const [input, setInput] = useState(JSON.parse(JSON.stringify(fieldSetup)));
  const [tableData, setTableData] = useState([]);
  const [showClearButton, setShowClearButton] = useState(false);

  //handle tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFieldChanged = (e) => {
    const id = e.target?.name || e.target?.id;
    const val = e.target.value;
    let inputTmp = input;
    inputTmp[id].value = val;

    if (id === 'mobile') {
      input['email'].value = '';
    } else if (id === 'email') {
      input['mobile'].value = '';
    }

    setInput({
      ...input,
      [id]: {
        ...input[id],
        value: val,
      },
    });
  };

  const getPotentialCustomer = async () => {
    await fetch(
      helper.getAPIHost() +
        `/api/Customer360/searchCustomerProfile?${
          customerID && 'CustomerNbr=' + customerID
        }${customerPhone && '&Phone=' + customerPhone}${
          customerEmail && '&Email=' + customerEmail
        }`
          .replaceAll('?&', '?')
          .replaceAll(null, ''),
      helper.apiHeaders(),
    )
      .then(async (response) => {
        let res = await response.json();
        if (res.status === 'OK') {
          if (res.result.customerID !== undefined) {
            //Customer data exists

            Object.keys(res.result).forEach((x) => {
              //Clean data
              if (
                typeof res.result[x] === 'string' &&
                res.result[x]?.toLowerCase() === 'null'
              ) {
                res.result[x] = null;
              }
            });

            let data = {
              customerID: res.result.customerID || '',
              customerEmail: res.result.email || '',
              customerPhone: res.result.phone?.replaceAll('-', '') || '',
              customerName: res.result.firstName || '',
            };

            setGuestData(data);
          } else {
            //Customer data not exists
            window.message.notification(
              'Customer Profile not found',
              enums.notificationType.W,
            );
            setGuestData(null); //Reset
            setToggleLookup(false);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPotentialCustomerNew = async () => {
    // if (input.email.value === '' && input.mobile.value === '') {
    //   alert('Please input value in field!');
    //   return;
    // }

    let email = customerEmail || input.email.value;
    let phone = customerPhone || input.mobile.value;

    await fetch(
      helper.getAPIHost() +
        `/api/Customer360/searchCustomerProfileList?Email=${email}&Phone=${phone}&page=1&take=10`,
      helper.apiHeaders(),
    )
      .then(async (response) => {
        let res = await response.json();
        if (res.status === 'OK') {
          console.log('Table Data set:', res.result);
          const updatedResult = res.result.map((val) => ({
            ...val,
            telephoneNr: phone ? phone : val.mobileNr ?? val.telephoneNr,
          }));

          // Duplicate the data by appending a copy of `updatedResult` to itself
          // const updatedResult = [...updatedResult1, ...updatedResult1];
          console.log(updatedResult.length);
          if (res.result.length > 0) {
            if (updatedResult.length === 1) {
              let data = {
                customerID: updatedResult[0].customerNr || '',
                customerEmail: updatedResult[0].email || '',
                customerPhone:
                  updatedResult[0].telephoneNr?.replaceAll('-', '') || '',
                customerName: updatedResult[0].customerNm || '',
                openOnlineCartInGuestConnect: true,
                searchAction: true,
              };

              console.log(data);
              setGuestData(data);
              updateProfileData(data); //Return customer data
              setShowClearButton(true);
            } else {
              setInput({
                ...input,
                email: {
                  ...input.email,
                  value: email, // Assuming `email` is a variable that holds the new email value
                },
                mobile: {
                  ...input.mobile,
                  value: phone, // Assuming `phone` is a variable that holds the new mobile value
                },
              });

              setToggleLookupNew(true);
              setTableData(updatedResult);
              setShowClearButton(true);
            }
          } else {
            window.message.notification(
              'Customer Profile not found',
              enums.notificationType.W,
            );
          }
        } else {
          window.message.notification(
            'Customer Profile not found',
            enums.notificationType.W,
          );
          // setTableData([]); //Reset
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getDataFromTable = (row) => {
    console.log(row);

    const val = row?.row;

    let data = {
      customerID: val.customerNr || '',
      customerEmail: val.email || '',
      customerPhone: val.telephoneNr?.replaceAll('-', '') || '',
      customerName: val.customerNm || '',
      openOnlineCartInGuestConnect: true,
      searchAction: true,
    };

    console.log(data);
    setGuestData(data);
    updateProfileData(data); //Return customer data
    setToggleLookupNew(false); //Close the popover
  };

  const lookupfunc = () => {
    if (customerEmail === '' && customerPhone === '') {
      alert('Please input Email or Phone in field!');
      return;
    }
    setIsLoading(true);
    getPotentialCustomerNew();
  };

  const clearData = () => {
    let data = {
      customerID: '',
      customerEmail: '',
      customerPhone: '',
      customerName: '',
      openOnlineCartInGuestConnect: false,
      searchAction: false,
    };

    console.log(data);
    setGuestData(data);
    updateProfileData(data); //Return customer data
    setShowClearButton(false);
    setDisableFunc(false);
  };

  useEffect(() => {
    if (toggleLookup) {
      //Call API to search potential guest on request
      setGuestData(null); //Reset
      setIsLoading(true);
      getPotentialCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleLookup]);

  return (
    <div className="potential-customer-component">
      {/* <RedButton
        label={'Lookup'}
        outline={false}
        id="potential-cust-button"
        onClick={() => setToggleLookup(true)}
        disabled={
          (customerID?.length > 0 ||
            customerEmail?.length > 0 ||
            customerPhone?.length > 0) &&
          !disabledProps
            ? false
            : true
        }
      /> */}

      {!showClearButton && !disabledProps && (
        <RedButton
          label={'Lookup'}
          outline={false}
          id="potential-cust-button"
          onClick={lookupfunc}
          disabled={isForward !== null || isReply !== null ? true : false}
          isLoading={isLoading} // Add the loading state here
        />
      )}

      {(disabledProps || showClearButton) && (
        <RedButton
          label={'Clear'}
          outline={false}
          id="potential-cust-button"
          onClick={clearData}
        />
      )}

      {/* keep first later can remove if user ok with new design BCP 2979 */}
      {toggleLookup && guestData && (
        <Popover
          className={`potential-customer-component ${props.popoverCustomClass}`}
          placement={props.placement ? props.placement : 'bottom'}
          isOpen={toggleLookup}
          target={'potential-cust-button'}
          //toggle={() => setToggleLookup(!toggleLookup)}
        >
          <PopoverHeader>
            <span>
              Potential Known Profile
              {/* <ModalBtnClose onClick={() => setToggleLookup(!toggleLookup)} /> */}
            </span>
          </PopoverHeader>
          <PopoverBody>
            {/* Loading */}
            {isLoading && (
              <div>
                <SpinnerLoading showLoadingText={true} />
              </div>
            )}

            {!isLoading && guestData && (
              <>
                <div>
                  Customer Profile found for {guestData.customerEmail}. Please
                  click below if you would like to link this{' '}
                  {origin ? origin : ''} to the Customer Profile.
                </div>

                <div className="bottom-button">
                  <RedButton
                    label="Connect to Customer Profile"
                    onClick={() => {
                      updateProfileData(guestData); //Return customer data
                      setDisableFunc(false);
                      setToggleLookup(false); //Close the popover
                    }}
                  />
                  <RedButton
                    label="Dismiss"
                    style={{ minWidth: '120px', marginLeft: '20px' }}
                    onClick={() => {
                      setToggleLookup(false); //Close the popover
                    }}
                  />
                </div>
              </>
            )}

            {/* No data */}
            {/* {!isLoading && !guestData && <div>No Customer Profile found</div>} */}
          </PopoverBody>
        </Popover>
      )}

      {toggleLookupNew && (
        <Popover
          className={`potential-customer-component ${props.popoverCustomClass}`}
          placement={props.placement ? 'left' : 'left'}
          isOpen={toggleLookupNew}
          target={'potential-cust-button'}
          // toggle={() => setToggleLookupNew(!toggleLookupNew)}
        >
          <PopoverHeader>
            <span>
              Potential Known Profile
              <ModalBtnClose
                onClick={() => setToggleLookupNew(!toggleLookupNew)}
              />
            </span>
          </PopoverHeader>
          <PopoverBody>
            {/* Loading */}
            {isLoading && (
              <div>
                <SpinnerLoading showLoadingText={true} />
              </div>
            )}

            {!isLoading && guestData && (
              <>
                <div className="tabs-container">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    centered
                    sx={{
                      '& .MuiTabs-indicator': {
                        display: 'none', // Hide the indicator
                      },
                    }}
                  >
                    <Tab label="By Mobile or Email" />
                    {/* <Tab label="By Name & Address" />
                    <Tab label="By Transaction" /> */}
                  </Tabs>
                  {value === 0 && (
                    <div>
                      <div className="input-group">
                        <div className="input-field">
                          <label htmlFor="mobile">{input.mobile.label}</label>
                          <Input
                            id="mobile"
                            type="text"
                            placeholder={input.mobile.placeholder}
                            value={input.mobile.value}
                            onChange={handleFieldChanged}
                          />
                        </div>
                        <div className="input-field">
                          <label htmlFor="email">{input.email.label}</label>
                          <Input
                            id="email"
                            type="email"
                            placeholder={input.email.placeholder}
                            value={input.email.value}
                            onChange={handleFieldChanged}
                          />
                        </div>
                      </div>
                      {/* <div className="button-container">
                        <RedButton
                          label="Go"
                          style={{ minWidth: '200px' }}
                          onClick={getPotentialCustomerNew}
                        />
                      </div> */}
                      <div>
                        <TableViewData
                          data={tableData}
                          callBackRow={getDataFromTable}
                        ></TableViewData>
                      </div>
                    </div>
                  )}
                  {value === 1 && <div>hello world 1</div>}
                  {value === 2 && <div>hello world 2</div>}
                </div>
              </>
            )}
          </PopoverBody>
        </Popover>
      )}
    </div>
  );
}

function TableViewData({ data, callBackRow }) {
  return (
    <div>
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={data} // Use data to populate rows
          getRowId={(row) => row.customerNr}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20]}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          columns={[
            {
              field: 'customerNr',
              headerName: 'Customer #',
              flex: 1,
              renderCell: (params) => (
                <span
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => callBackRow(params)}
                >
                  {params.value}
                </span>
              ),
            },
            { field: 'customerNm', headerName: 'Customer Name', flex: 1 },
            { field: 'telephoneNr', headerName: 'Phone #', flex: 1 },
            { field: 'email', headerName: 'Email', flex: 1 },
          ]}
        />
      </Box>
    </div>
  );
}
