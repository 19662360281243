import React from 'react';
import AuthPage from './application-center/login-center';
import BDFLandingPage from './application-center/login-center/landing';
import BDFLandingLegacyPage from './application-center/login-center/landing-legacy';
import ExternalAccessPage from './bdf-applications/others/external';
import ExternalTestPage from './bdf-applications/others/external/test-iframe';
import TabletHelpPage from './bdf-applications/others/guide-files/TabletViewHelp/index';

export const LoginCenter = AuthPage;
export const LandingPage = BDFLandingPage;
export const LandingLegacyPage = BDFLandingLegacyPage;
export const ExternalAccess = ExternalAccessPage;
export const ExternalTest = ExternalTestPage;
export const TabletHelp = TabletHelpPage;

export const DashboardPage = React.lazy(() =>
  import('./application-center/dashboard'),
);
export const UnderConstruction = React.lazy(() =>
  import('./components/UnderConstruction'),
);

export const routes = [
  {
    path: '/pipeline',
    component: React.lazy(() => import('./bdf-applications/pipelines')),
  },
  {
    path: '/writesales',
    component: React.lazy(() =>
      import('./bdf-applications/orders-management/write-sales'),
    ),
  },
  {
    path: '/openorders',
    component: React.lazy(() =>
      import('./bdf-applications/orders-management/deliver-sales'),
    ),
  },
  {
    path: '/repeat',
    component: React.lazy(() =>
      import('./bdf-applications/orders-management/repeat-sales'),
    ),
  },
  {
    path: '/customer',
    component: React.lazy(() =>
      import('./bdf-applications/customers/customer-search'),
    ),
  },
  {
    path: '/customer:name',
    component: React.lazy(() =>
      import('./bdf-applications/customers/customer-search'),
    ),
  },
  {
    path: '/merchandise',
    component: React.lazy(() => import('./bdf-applications/merchandise')),
  },
  {
    path: '/coaching',
    component: React.lazy(() =>
      import('./bdf-applications/coaching/action-items'),
    ),
  },
  {
    path: '/feedback',
    component: React.lazy(() => import('./application-center/feedback-center')),
  },
  {
    path: '/help',
    component: React.lazy(() => import('./bdf-applications/help-page')),
  },
  {
    path: '/help_delivery',
    component: React.lazy(() =>
      import('./bdf-applications/help-page/delivery'),
    ),
  },
  {
    path: '/help_b4b',
    component: React.lazy(() => import('./bdf-applications/help-page/misc')),
  },
  // {
  //   path: '/feedback',
  //   component: React.lazy(() => import('./application-center/feedback-center')),
  // },
  {
    path: '/commission',
    component: React.lazy(() =>
      import('./bdf-applications/commission-details'),
    ),
  },
  {
    path: '/bucket-assignment',
    component: React.lazy(() =>
      import('./bdf-applications/bucket-assignment-management'),
    ),
  },
];
