import React, { forwardRef, useState } from 'react';
import { Modal, ModalBody /*ModalHeader*/ } from 'reactstrap';
import { getCustomersTimelineImages } from '../data';

// Manually import each image
import slide21 from '../../../../../assets/img/cordial/slide2-1.png';
import slide31 from '../../../../../assets/img/cordial/slide3-1.png';
import slide41 from '../../../../../assets/img/cordial/slide4-1.png';
import slide42 from '../../../../../assets/img/cordial/slide4-2.png';
import slide43 from '../../../../../assets/img/cordial/slide4-3.png';
import slide51 from '../../../../../assets/img/cordial/slide5-1.png';
import slide52 from '../../../../../assets/img/cordial/slide5-2.png';
import slide53 from '../../../../../assets/img/cordial/slide5-3.png';
import slide61 from '../../../../../assets/img/cordial/slide6-1.png';
import slide62 from '../../../../../assets/img/cordial/slide6-2.png';
import slide71 from '../../../../../assets/img/cordial/slide7-1.png';
import slide72 from '../../../../../assets/img/cordial/slide7-2.png';
import slide81 from '../../../../../assets/img/cordial/slide8-1.png';
import slide82 from '../../../../../assets/img/cordial/slide8-2.png';
import slide83 from '../../../../../assets/img/cordial/slide8-3.png';
import slide84 from '../../../../../assets/img/cordial/slide8-4.png';
import slide91 from '../../../../../assets/img/cordial/slide9-1.png';
import slide92 from '../../../../../assets/img/cordial/slide9-2.jpg';
import slide93 from '../../../../../assets/img/cordial/slide9-3.png';
import slide101 from '../../../../../assets/img/cordial/slide10-1.png';
import slide111 from '../../../../../assets/img/cordial/slide11-1.png';
import slide121 from '../../../../../assets/img/cordial/slide12-1.png';
import slide122 from '../../../../../assets/img/cordial/slide12-2.png';
import slide131 from '../../../../../assets/img/cordial/slide13-1.png';
import slide141 from '../../../../../assets/img/cordial/slide14-1.png';
import slide142 from '../../../../../assets/img/cordial/slide14-2.png';
import slide143 from '../../../../../assets/img/cordial/slide14-3.png';
import slide144 from '../../../../../assets/img/cordial/slide14-4.png';
import slide151 from '../../../../../assets/img/cordial/slide15-1.png';
import slide152 from '../../../../../assets/img/cordial/slide15-2.png';
import slide153 from '../../../../../assets/img/cordial/slide15-3.png';
import slide161 from '../../../../../assets/img/cordial/slide16-1.png';
import slide162 from '../../../../../assets/img/cordial/slide16-2.png';
import slide163 from '../../../../../assets/img/cordial/slide16-3.png';
import slide171 from '../../../../../assets/img/cordial/slide17-1.png';
import slide172 from '../../../../../assets/img/cordial/slide17-2.png';
import slide173 from '../../../../../assets/img/cordial/slide17-3.png';
import slide181 from '../../../../../assets/img/cordial/slide18-1.png';
import slide191 from '../../../../../assets/img/cordial/slide19-1.png';
import slide201 from '../../../../../assets/img/cordial/slide20-1.png';
import slide221 from '../../../../../assets/img/cordial/slide22-1.png';
import slide222 from '../../../../../assets/img/cordial/slide22-2.png';
import slide223 from '../../../../../assets/img/cordial/slide22-3.png';
import slide231 from '../../../../../assets/img/cordial/slide23-1.png';
import slide232 from '../../../../../assets/img/cordial/slide23-2.png';
import slide233 from '../../../../../assets/img/cordial/slide23-3.png';
import slide234 from '../../../../../assets/img/cordial/slide23-4.png';
import slide241 from '../../../../../assets/img/cordial/slide24-1.png';
import slide242 from '../../../../../assets/img/cordial/slide24-2.png';
import slide243 from '../../../../../assets/img/cordial/slide24-3.png';

// Map of image names to imports
const cordialImages = {
  'slide2-1.png': slide21,
  'slide3-1.png': slide31,
  'slide4-1.png': slide41,
  'slide4-2.png': slide42,
  'slide4-3.png': slide43,
  'slide5-1.png': slide51,
  'slide5-2.png': slide52,
  'slide5-3.png': slide53,
  'slide6-1.png': slide61,
  'slide6-2.png': slide62,
  'slide7-1.png': slide71,
  'slide7-2.png': slide72,
  'slide8-1.png': slide81,
  'slide8-2.png': slide82,
  'slide8-3.png': slide83,
  'slide8-4.png': slide84,
  'slide9-1.png': slide91,
  'slide9-2.jpg': slide92,
  'slide9-3.png': slide93,
  'slide10-1.png': slide101,
  'slide11-1.png': slide111,
  'slide12-1.png': slide121,
  'slide12-2.png': slide122,
  'slide13-1.png': slide131,
  'slide14-1.png': slide141,
  'slide14-2.png': slide142,
  'slide14-3.png': slide143,
  'slide14-4.png': slide144,
  'slide15-1.png': slide151,
  'slide15-2.png': slide152,
  'slide15-3.png': slide153,
  'slide16-1.png': slide161,
  'slide16-2.png': slide162,
  'slide16-3.png': slide163,
  'slide17-1.png': slide171,
  'slide17-2.png': slide172,
  'slide17-3.png': slide173,
  'slide18-1.png': slide181,
  'slide19-1.png': slide191,
  'slide20-1.png': slide201,
  'slide22-1.png': slide221,
  'slide22-2.png': slide222,
  'slide22-3.png': slide223,
  'slide23-1.png': slide231,
  'slide23-2.png': slide232,
  'slide23-3.png': slide233,
  'slide23-4.png': slide234,
  'slide24-1.png': slide241,
  'slide24-2.png': slide242,
  'slide24-3.png': slide243,
};

const CompleteMerchandiseRequest = forwardRef(({ data }) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [resultImg, setResultImg] = useState([]);

  // useEffect(() => {
  //   if (data) {
  //     checkImageTrue(data);
  //   }
  // }, [data]);

  const checkImageTrue = async (data) => {
    let res = await getCustomersTimelineImages(data.Remarks);

    if (res.success) {
      setResultImg(res.result);
      setShowLink(true);
    } else {
      setShowLink(false);
      setResultImg([]);
    }
    setIsModalShown(true);
  };

  const getImageSrc = (imageName) => cordialImages[imageName] || null;

  return (
    <>
      {/* {showLink && ( */}
      <div className="showImg" onClick={() => checkImageTrue(data)}>
        Show Example
      </div>
      {/* )} */}

      {showLink && (
        <Modal
          isOpen={isModalShown}
          toggle={() => setIsModalShown(false)}
          className="cust360ContactTimeline-modal"
          contentClassName="custom-modal"
        >
          {/* <ModalHeader>{resultImg.messageName}</ModalHeader> */}
          <ModalBody
            className="cust360ContactTimeline-modal-body"
            style={{ padding: '25px 20px 25px 20px' }}
          >
            <div className="image-container">
              {resultImg?.slideImages ? (
                <img
                  src={getImageSrc(resultImg?.slideImages)}
                  alt={resultImg?.messageName || 'Image'}
                  className="modal-image"
                />
              ) : (
                <p>No image available</p>
              )}
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
});

export default CompleteMerchandiseRequest;
