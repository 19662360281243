import React, { useState, useEffect, useRef } from 'react';

//Components
import {
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
} from '@mui/material';
import { RedButton } from '../../components/CommonComponent';

//SCSS
import './product-chatbot.scss';

//Helpers
const helper = require('../../utils/helper');

const ChatPrompt = ({ isFullScreen, onOptionSelect }) => {
  const [selectedOptions, setSelectedOptions] = useState({
    0: null, // customer type
    1: null, // living space
    2: null, // priority
    3: null, // special consideration
  });
  const [productSearch, setProductSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const steps = [
    {
      title: 'Step 1: Define customer type (required)',
      options: ['Individual', 'Couple', 'Family', 'Situationship'],
      isRequired: true,
    },
    {
      title: 'Step 2: Define living space size',
      options: ['Tiny home', 'Small', 'Medium', 'Large'],
      isRequired: false,
    },
    {
      title: 'Step 3: Customer priorities',
      options: ['Comfort', 'Style/design', 'Durability', 'Space/efficiency'],
      isRequired: false,
    },
    {
      title: 'Step 4: Special considerations',
      options: ['Pets', 'Physical needs', 'Hosting needs', 'Budget'],
      isRequired: false,
    },
  ];

  const handleOptionSelect = (option, stepIndex) => {
    setSelectedOptions((prev) => ({
      ...prev,
      // If the same option is clicked again, unselect it by setting to null
      [stepIndex]: prev[stepIndex] === option ? null : option,
    }));
  };

  const isValidSelection = () => {
    // First check if Q1 (customer type) is answered
    if (!selectedOptions[0]) return false;

    // Check if either a product/set family is searched OR at least one other question is answered
    return (
      productSearch.trim() !== '' ||
      selectedOptions[1] !== null ||
      selectedOptions[2] !== null ||
      selectedOptions[3] !== null
    );
  };

  const getArticle = (word) => {
    // Convert to lowercase for consistency
    word = word.toLowerCase();
    // Check if the word starts with a vowel sound
    return /^[aeiou]/i.test(word) ? 'an' : 'a';
  };

  const generatePrompt = () => {
    if (!isValidSelection()) return;

    const promptParts = [];

    // Q1 (Always included)
    const customerType = selectedOptions[0].toLowerCase();
    promptParts.push(
      `I'm working with ${getArticle(customerType)} ${customerType}`,
    );

    // Q2 (Optional)
    if (selectedOptions[1]) {
      const spaceSize = selectedOptions[1].toLowerCase();
      promptParts.push(
        `They live in ${getArticle(spaceSize)} ${spaceSize} space`,
      );
    }

    // Q3 (Optional)
    if (selectedOptions[2]) {
      const priority = selectedOptions[2].toLowerCase();
      promptParts.push(`Their primary priority is ${priority}`);
    }

    // Q4 (Optional)
    if (selectedOptions[3]) {
      const consideration = selectedOptions[3].toLowerCase();
      promptParts.push(`They have ${consideration} as a special consideration`);
    }

    // Add product search if provided
    if (productSearch.trim()) {
      promptParts.push(
        `They are currently looking at the ${productSearch.trim()}`,
      );
    }

    // Join all parts and add the final question
    const prompt =
      promptParts.join('. ') +
      '. Could you provide me with three talking points to engage with the customer?';

    onOptionSelect(prompt);
  };

  // API call function to search family codes
  const searchFamilyCode = async (query) => {
    try {
      setIsSearching(true);
      const response = await fetch(
        helper.getAPIHost() +
          `/api/insight/getFamilyCodeDescList?keyword=${encodeURIComponent(
            query,
          )}`,
        {
          method: 'GET',
          ...helper.apiHeaders(),
        },
      );
      const data = await response.json();
      setSearchResults(data.result);
    } catch (error) {
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  // Create a ref for the debounced function
  const debouncedSearchRef = useRef();

  // Initialize debounced function
  useEffect(() => {
    const timeoutId = { current: null };

    debouncedSearchRef.current = (query) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        if (query.trim().length >= 2) {
          searchFamilyCode(query);
        } else {
          setSearchResults([]);
        }
      }, 500);
    };

    // Cleanup
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  // Effect to trigger search when productSearch changes
  useEffect(() => {
    if (debouncedSearchRef.current) {
      debouncedSearchRef.current(productSearch);
    }
  }, [productSearch]);

  const calculateMargin = () => {
    const zoomLevel = parseInt(localStorage.getItem('zoomLevel') || '100');

    if (isFullScreen) {
      return {
        left: zoomLevel === 80 ? 255 : 110,
        top: zoomLevel === 80 ? 120 : 60,
      };
    } else {
      return {
        left: zoomLevel === 80 ? 255 : 110,
        top: zoomLevel === 80 ? -100 : 50,
      };
    }
  };

  return (
    <Box
      className={`chat-prompt-container ${isFullScreen ? 'full-screen' : ''}`}
    >
      <Typography variant="subtitle1" className="prompt-header">
        Don't feel like typing? Be lazy here...
      </Typography>

      {steps.map((step, stepIndex) => (
        <Box key={stepIndex} className="prompt-step">
          <Typography variant="body2" className="step-title">
            {step.title}
          </Typography>
          <Box className="options-container">
            {step.options.map((option, optionIndex) => (
              <Button
                key={optionIndex}
                variant="outlined"
                className={`option-button ${
                  selectedOptions[stepIndex] === option ? 'selected' : ''
                }`}
                onClick={() => handleOptionSelect(option, stepIndex)}
              >
                {option}
              </Button>
            ))}
          </Box>
        </Box>
      ))}

      <Box className="prompt-footer">
        <Autocomplete
          className="product-search"
          freeSolo
          options={searchResults}
          loading={isSearching}
          value={productSearch}
          onInputChange={(event, newValue) => {
            setProductSearch(newValue);
          }}
          slotProps={{
            popper: {
              sx: {
                zIndex: 5001,
                marginLeft: `${calculateMargin().left}px !important`,
                marginTop: `${calculateMargin().top}px !important`,
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Product or Set Family Search"
              variant="outlined"
              placeholder="e.g., Hudson Gray Bed"
              size="small"
            />
          )}
        />
        <RedButton
          label="Generate Prompt"
          disabled={!isValidSelection()}
          onClick={generatePrompt}
        />
      </Box>
    </Box>
  );
};

export default ChatPrompt;
